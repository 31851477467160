import React, { Component } from "react";
import "../../../css/partner-with-us.css";

class ConnectWithUsFloatingCta extends Component {
  componentDidMount() {
    function isScrolledToBottom() {
      return (
        window.innerHeight + window.scrollY >= document.body.offsetHeight - 600
      );
    }

    this.hideDivIfScrolledToBottom = () => {
      if (isScrolledToBottom()) {
        document.getElementById("connect_with_us_floating_btn").style.display =
          "none";
      } else {
        document.getElementById("connect_with_us_floating_btn").style.display =
          "block";
      }
    };

    window.addEventListener("scroll", this.hideDivIfScrolledToBottom);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.hideDivIfScrolledToBottom);
  }

  render() {
    return (
      <div
        id="connect_with_us_floating_btn"
        className="floating_connect_with_us"
        onClick={() => {
          var contact_us_form = document.getElementById("contact_us");
          if (contact_us_form) {
            contact_us_form.scrollIntoView({
              behavior: "smooth",
            });
          }
        }}
      >
        <button className="floating_connect_with_us_cta">Connect With Us</button>
      </div>
    );
  }
}

export default ConnectWithUsFloatingCta;
