import React, { Component } from "react";
import "../../../css/partner-with-us.css";
import { Col, Container, Row } from "react-bootstrap";

class ResultCard extends Component {
  render() {
    const { img, label, desc } = this.props;
    return (
      <div className="partner_result_card" style={{ height: "100%" }}>
        <div className="d-flex flex-row justify-content-center">
          <img src={img} className="partner_result_card_img" />
        </div>
        <h1 className="partner_result_card_label">{label}</h1>
        <p className="partner_result_card_desc">{desc}</p>
      </div>
    );
  }
}

class PartnerResult extends Component {
  render() {
    const { data, assets } = this.props;
    if (!data) return null;
    return (
      <Container fluid className="c2 partner_result_container">
        <div className="container-lg partner_reason_root_content">
          <h1 className="partner_reason_header section_heading">{data.title}</h1>
          <div className=" d-flex flex-row justify-content-center">
            <div className="partner_reason_content">
              {data.cards?.map((item, i) => {
                return (
                  <div key={i} className=" d-flex flex-row justify-content-center">
                    <ResultCard
                      img={assets?.[item.img]?.url}
                      label={item.label}
                      desc={item.desc}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default PartnerResult;
