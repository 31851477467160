import React, { Component } from "react";
import "../../../css/partner-with-us.css";
import { Carousel, Container } from "react-bootstrap";
import ModalVideo from "react-modal-video";
import PARTNER_SUCCESS from "../../../images/partner-success.svg";

class Curve extends Component {
  render() {
    return (
      <div
        style={{
          width: "100%",
          backgroundColor: "#fff",
          height: "150px",
          marginBottom: "-100px",
        }}
      >
        <div style={{ height: "150px", overflow: "hidden" }}>
          <svg
            viewBox="0 0 500 150"
            preserveAspectRatio="none"
            style={{ height: "100%", width: "100%" }}
          >
            <path
              d="M0.00,49.98 C259.99,150.00 233.74,151.00 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
              style={{ stroke: "none", fill: "#f3e9fe" }}
            ></path>
          </svg>
        </div>
      </div>
    );
  }
}

class CollabDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoId: null,
    };
  }

  render() {
    const { data, assets } = this.props;
    const { videoId } = this.state;
    if (!data) return null;
    return (
      <div className="collab_detail_root">
        <div className="collab_detail_section_one">
          <div className="container-lg">
            <Container fluid className="c2 c-pad itc_collab_container_root">
              <div className="itc_collab_container">
                <h1 className="itc_collab_header section_heading">
                  {data.labelOne}
                </h1>
                <div className="itc_collab_content">
                  <h1 className="">{data.labelTwo}</h1>
                  <p className="">{data.descOne}</p>
                </div>
              </div>
            </Container>
          </div>
        </div>
        <Curve />
        <div className="container-lg">
          <Container fluid className="c2 c-pad position-relative">
            <div className="collab_detail_content">
              <div className="collab_detail_content-carousel">
                <div>
                  <h1 className="collab_detail_header section_heading">
                    {data.labelThree}
                  </h1>
                  <h1 className="collab_detail_desc section_subheading">
                    {data.descTwo}
                  </h1>
                </div>
                <div className="d-flex flex-row justify-content-center ">
                  <Carousel
                    indicators={data.video.length > 1}
                    prevIcon={false}
                    prevLabel={"false"}
                    nextIcon={false}
                    nextLabel={"false"}
                    className="hero_carousel collab_detail_carousel"
                  >
                    {data.video?.map((item, id) => {
                      return (
                        <Carousel.Item
                          className="collab_details_carousel_item"
                          key={id}
                          onClick={() => this.setState({ videoId: item.id })}
                        >
                          <img
                            src={assets?.[item.thumbnail]?.url}
                            className="collab_detail_carousel_img"
                          />
                        </Carousel.Item>
                      );
                    })}
                  </Carousel>
                </div>
              </div>

              <div className="collab_detail_content">
                <h1 className="collab_detail_header_two section_heading">
                  {data.labelFour}
                </h1>
                <div className="collab_detail_img_container">
                  <img
                    src={assets?.[data.mainImg]?.url}
                    alt="Partner success"
                  />
                </div>
              </div>
            </div>
            <ModalVideo
              channel="youtube"
              isOpen={videoId}
              videoId={videoId}
              onClose={() => {
                this.setState({ videoId: null });
              }}
              autoplay
            />
          </Container>
        </div>
      </div>
    );
  }
}

export default CollabDetail;
